


// TODO: rewrite using class-based @Component syntax
import Vue from 'vue';
import createEvent from '@/views/CreateEvent.vue';
import EditEvent from '@/_modules/events/components/edit-event/edit-event.vue';

import { mapGetters } from 'vuex';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';

interface ICabinetMainPageData {
  tempData: boolean;
}

interface ICabinetMainPageMethods {
  cancelEditing: (eventId: number | void) => Promise<void>;
}

interface ICabinetMainPageComputed {
  event: TEvent;
  isEventOrganizer: boolean;
  contact: TContact;
}

const CabinetMainPage = Vue.extend<ICabinetMainPageData, ICabinetMainPageMethods, ICabinetMainPageComputed>({
  name: 'cabinet-main-page',
  components: {
    createEvent,
    EditEvent
  },
  computed: {
    ...mapGetters('_eventStore', {
      event: 'event',
    }),

    ...mapGetters('promoPageStore', {
      contact: 'contact',
    }),

    isEventOrganizer(): boolean {
      if (this.event && this.contact && this.contact.user) {
        return this.event.creator_user_id === this.contact.user.id;
      }
      return false;
    },

  },
  data(): ICabinetMainPageData {
    return {
      tempData: true, // TODO: remove this
    };
  },
  methods: {
    async cancelEditing(eventId: number | void): Promise<void> {

      /* If eventId is present ( =0 is not possible, btw)
       * then it means that CreateEvent wants to «view saved event».
       * Else (if there is no eventId) it means normal cancelling of stuff.
       * We were young... that code is not close to the ideal
       */
      if (eventId) {
        await this.$store.dispatch('eventStore/event', this.$route.params.eventId);
        this.$router.push({
          name: 'event-info',
          params: {
            eventId: this.$route.params.eventId
          }
        });
      } else {
        // let's just reload the page for now, because otherwise it is hard to cancel some fields which are child components
        // TODO: proper cancel or page redesign
        this.$router.go(0);
      }

    },
  }
});
export default CabinetMainPage;
